<template>
  <div class="main-padding top tophead pos-rel">
    <div class="">
      <div class="top-title flex-shrink">
        DeFi+613 {{ $t("协议") }}<br />= {{ $t("无限未来") }}
      </div>
      <div class="flex flex-wrap mt-60">
        <div
          class="top-btn ani-move cur"
          @click="delayFun(jump, webInfo.pancakeswap_buy)"
        >
          {{ $t("在Pancakeswap上购买") }}
        </div>
        <div class="top-btn ani-move cur" @click="delayFun(jumpWhitePaper)">
          {{ $t("白皮书") }}
        </div>
        <div
          class="top-btn ani-move cur"
          @click="delayFun(jump, webInfo.audit)"
        >
          {{ $t("审计") }}
        </div>
        <div class="top-btn ani-move cur" @click="delayFun(jumpDocument)">
          {{ $t("文档") }}
        </div>
        <div class="top-btn ani-move cur" @click="delayFun(jumpXieyi)">
          {{ $t("协议玩法") }}
        </div>
        <div class="top-btn ani-move cur" @click="delayFun(jumpJieDian)">
          {{ $t("节点权益") }}
        </div>
        <div class="top-btn ani-move cur" @click="openpop(1)">
          613 {{ $t("协议合约地址") }}
        </div>
        <div class="top-btn ani-move cur" @click="openpop(2)">
          BT613 {{ $t("代币地址") }}
        </div>
      </div>
    </div>
    <img
      src="../assets/images/img1.png"
      class="img1 flex-shrink"
    />
    <!-- <cus-popup :address="address" :qrcodeUrl="qrcodeUrl" @close="show=false" v-if="show"></cus-popup> -->

    <div class="cusPopup" v-show="show">
      <div class="cuspop-card">
        <img
          src="../assets/images/closepop.png"
          class="closepop ani-move cur"
          @click="show=false"
        />
        <div class="text-center pt-30">
          <img src="../assets/images/xydz.png" class="xydz" v-show="type==1" />
          <img src="../assets/images/hydz.png" class="xydz" v-show="type==2" />
        </div>
        <!-- <div class="cuspop-box mt-20" ref="aaa">
          <div class="size-18 text-bold" style="color: #5a5c5a">地址:</div>
          <div class="size-16 mt-10 word-warp">{{ address }}</div>
        </div> -->
        <img src="../assets/images/xydzAddress.png" class="addressBox" v-show="type==1">
        <img src="../assets/images/hydzAddress.png" class="addressBox" v-show="type==2">
        <div class="cuspop-btn mt-20 size-18 cur ani-move" ref="copyBtn">
          {{$t('复制地址')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { mapGetters } from "vuex";
export default {
  name: "TopHead",
  computed: {
    ...mapGetters(["lang", "webInfo"]),
  },
  data(){
    return {
      address:'',
      qrcodeUrl:'',
      show:false,
      type:1,
      clip:null
    }
  },
  mounted(){
    console.log(this.$refs.copyBtn);
    
    this.clip = new Clipboard(this.$refs.copyBtn, {
      text: () => this.address,
    });
    this.clip.on("success", (e) => {
      this.showToast(this.$t("已复制"), "success");
    });
  },
  beforeDestroy() {
    this.clip.destroy();
  },
  methods: {
    openpop(type){
      if(type==1){
        this.address = '0xE482380116A988AfDD1bC0DAC35b0cCDF23BB613'
      }else{
        this.address = '0x587795D8bb4D1CAeE25E4Ce81d03B8fdB221a20A'
      }
      this.type = type
      this.show = true
    },
    jump(url) {
      window.open(url);
    },
    jumpWhitePaper() {
      if (this.lang == "zh") {
        window.open(this.webInfo.white_paper_cn);
      } else {
        window.open(this.webInfo.white_paper_en);
      }
    },
    jumpDocument() {
      if (this.lang == "zh") {
        window.open(this.webInfo.document_cn);
      } else {
        window.open(this.webInfo.document_en);
      }
    },
    jumpXieyi() {
      window.open("https://s3.613protocol.com/613/cn_3.pdf");
    },
    jumpJieDian() {
      window.open(
        "https://s3.613protocol.com/613/613%E5%8D%8F%E8%AE%AE%E5%85%A8%E7%90%83%E8%8A%82%E7%82%B9%E6%8B%9B%E5%8B%9F%E8%AE%A1%E5%88%92%20.png"
      );
    }
  },
};
</script>

<style lang="scss">
.img1 {
  max-width: 100%;
  width: 4.47rem;
  height: 5.5rem;
  animation: img1 0.5s linear, topImg 3s linear infinite;
}
.top {
  padding-top: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .top-title {
    font-size: 0.58rem;
    font-weight: bold;
    opacity: 0;
    animation: topTitle 0.5s linear forwards;
  }
}
.top-btn {
  background-color: #00e36f;
  font-weight: bold;
  color: #131513;
  height: 0.56rem;
  line-height: 0.56rem;
  border-radius: 0.28rem;
  padding: 0 0.3rem;
  font-size: 0.16rem;
  margin-right: 0.06rem;
  margin-bottom: 0.1rem;
}

@keyframes topTitle {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes img1 {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes topImg {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-0.1rem);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(0.1rem);
  }
  100% {
    transform: translateY(0);
  }
}
@media (min-width:768px) {
    .tophead{
      flex-wrap: nowrap !important;
    }
}
@media (max-width:768px) {
  .tophead{
      flex-wrap: wrap !important;
    }
}

.cusPopup {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
  .cuspop-card {
    width: 4.8rem;
    padding: 0.3rem;
    border-radius: 0.4rem;
    background-color: #1f211f;
    animation: cusPopShow 0.3s linear forwards;
    position: relative;
    .closepop {
      width: 0.36rem;
      height: 0.36rem;
      position: absolute;
      top: 0.3rem;
      right: 0.3rem;
    }
    .xydz {
      width: 2.6rem;
      height: 2.6rem;
    }
    .cuspop-box {
      padding: 0.2rem 0.3rem;
      border-radius: 0.2rem;
      background-color: #181a18;
      text-align: center;
    }
    .cuspop-btn {
      height: 0.6rem;
      line-height: 0.6rem;
      border-radius: 0.3rem;
      text-align: center;
      background-color: #00e36f;
      color: #0e100e;
      font-weight: bold;
    }
    .addressBox{
      width: 100%;
      height: 1rem;
    }
  }
}

@keyframes cusPopShow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
</style>